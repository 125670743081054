<template>
  <div class="app-container online-info-page">
    <!-- 添加或修改会员对话框 -->
    <!-- 添加或修改招聘职位对话框 -->
    <el-form ref="form" :model="form" size="small" :rules="rules" label-width="150px">
      <el-form-item label="模板名称" prop="templateName">
        <el-input class="input-750" v-model="form.templateName" placeholder="模板名称" />
      </el-form-item>

      <el-form-item label="城市区域" prop="cityRegion">
        <el-select class="input-750" v-model="form.cityRegion" placeholder="">
          <el-option v-for="item in cityList" :label="item.name" :value="item.key" />
        </el-select>
      </el-form-item>

      <el-form-item label="户口类型" prop="nativeIds">
        <el-select multiple class="input-750" v-model="form.nativeIds" placeholder="">
          <el-option v-for="item in nativeList" :label="item.name" :value="`${item.id}`" />
        </el-select>
      </el-form-item>
    </el-form>

    <table>
      <tbody>
        <tr>
          <td class="text-center bold" />
          <td class="text-center bold" colspan="2">个人</td>
          <td class="text-center bold" colspan="2">公司</td>
          <td class="text-center bold" colspan="2">基数范围</td>
        </tr>
        <tr>
          <td class="text-center" />
          <td class="text-center">类型</td>
          <td class="text-center">值</td>
          <td class="text-center">类型</td>
          <td class="text-center">值</td>
          <td class="text-center">最小</td>
          <td class="text-center">最大</td>
        </tr>

        <tr>
          <td class="td-1">养老：</td>
          <td class="td-2">
            <el-select v-model="form.pension.selfRatioType">
              <el-option :value="1" label="比例" />
              <el-option :value="2" label="固定值" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.pension.selfValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-select v-model="form.pension.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.pension.companyValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-input v-model="form.pension.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.pension.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">医疗：</td>
          <td class="td-2">
            <el-select v-model="form.medical.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.medical.selfValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-select v-model="form.medical.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.medical.companyValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-input v-model="form.medical.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.medical.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">工伤：</td>
          <td class="td-2">
            <el-select v-model="form.employmentInjury.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.employmentInjury.selfValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-select v-model="form.employmentInjury.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.employmentInjury.companyValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-input v-model="form.employmentInjury.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.employmentInjury.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">生育：</td>
          <td class="td-2">
            <el-select v-model="form.maternity.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.maternity.selfValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-select v-model="form.maternity.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.maternity.companyValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-input v-model="form.maternity.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.maternity.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">失业：</td>
          <td class="td-2">
            <el-select v-model="form.unemployment.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.unemployment.selfValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-select v-model="form.unemployment.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.unemployment.companyValue" placeholder="示例：0.001" />
          </td>
          <td class="td-2">
            <el-input v-model="form.unemployment.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.unemployment.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">残保金：</td>
          <td class="td-2">
            <el-select disabled v-model="form.disabilityInsurance.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input disabled v-model="form.disabilityInsurance.selfValue"
                      placeholder="示例：0" />
          </td>
          <td class="td-2">
            <el-select v-model="form.disabilityInsurance.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.disabilityInsurance.companyValue" placeholder="示例：0" />
          </td>
          <td class="td-2">
            <el-input v-model="form.disabilityInsurance.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.disabilityInsurance.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
        <tr>
          <td class="td-1">大额医疗：</td>
          <td class="td-2">
            <el-select v-model="form.largeMedical.selfRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.largeMedical.selfValue" placeholder="示例：0" />
          </td>
          <td class="td-2">
            <el-select v-model="form.largeMedical.companyRatioType">
              <el-option label="比例" :value="1" />
              <el-option label="固定值" :value="2" />
            </el-select>
          </td>
          <td class="td-2">
            <el-input v-model="form.largeMedical.companyValue" placeholder="示例：0" />
          </td>
          <td class="td-2">
            <el-input v-model="form.largeMedical.baseMin" placeholder="最小，示例：5000" />
          </td>
          <td class="td-2">
            <el-input v-model="form.largeMedical.baseMax" placeholder="最大，示例：30000" />
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  insurancefundInsuranceList,
  insurancefundInsuranceSave,
  insurancefundInsuranceUpdate,
  insurancefundInsuranceDelete,
  insurancefundInsuranceInfo
} from '@/api/insurancefund';

import { listCityRegions, getNativeList } from '@/api/comm';
import _ from 'lodash';

export default {

  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      cityList: [],

      // 表单参数
      form: {
        pension: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        medical: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        unemployment: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        employmentInjury: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        maternity: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        largeMedical: {
          selfRatioType: 2,
          companyRatioType: 2
        },
        disabilityInsurance: {
          selfRatioType: 2,
          selfValue: 0,
          companyRatioType: 2
        }
      },
      // 表单校验
      rules: {
        positionName: [
          {
            required: true,
            message: '职位名称不能为空',
            trigger: 'blur'
          },
        ],
        nativeIds: [
          {
            required: true,
            message: '职位名称不能为空',
            trigger: 'blur'
          },
        ],
        type: [
          {
            required: true,
            message: '招聘形式不能为空',
            trigger: 'change'
          },
        ],
      },
      nativeList: [],
    };
  },
  created() {
    const { id } = this.$route.params;
    if (this.$route.params.id !== '-1') {
      setTimeout(() => {
        document.title = '编辑社保模板';
        this.handleUpdate(id);
      }, 800);
    } else {
      setTimeout(() => {
        document.title = '新增社保模板';
        this.handleAdd();
      }, 800);
    }

    // this.getList();
    this.getCityList();
    this.getNativeList();
  },
  methods: {
    getNativeList() {
      getNativeList()
        .then(({ data }) => {
          this.nativeList = data;
        });
    },
    getCityList() {
      listCityRegions()
        .then(({ data }) => {
          this.cityList = data;
        });
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      insurancefundInsuranceList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.form = {
        pension: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        medical: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        unemployment: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        employmentInjury: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        maternity: {
          selfRatioType: 1,
          companyRatioType: 1
        },
        largeMedical: {
          selfRatioType: 2,
          companyRatioType: 2
        },
        disabilityInsurance: {
          selfRatioType: 2,
          selfValue: 0,
          companyRatioType: 2
        }
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
      if (this.cityList.length > 0) {
        this.form.cityRegion = this.cityList[0].key;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      insurancefundInsuranceInfo(id)
        .then((response) => {
          const nativeIds = _.split(response.data.nativeIds, ',');
          const filterNativeIds = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < nativeIds.length; i++) {
            if (nativeIds[i] !== '') {
              filterNativeIds.push(`${nativeIds[i]}`);
            }
          }

          this.form = {
            ...response.data,
            nativeIds: filterNativeIds,
            cityRegion: `${response.data.cityId}-${response.data.regionId}`
          };
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      insurancefundInsuranceUpdate({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        this.form.cityId = this.form.cityRegion.split('-')[0];
        this.form.regionId = this.form.cityRegion.split('-')[1];

        const params = {
          ...this.form,
          nativeIds: `,${this.form.nativeIds.join(',')},`,
          cityId: this.form.cityRegion.split('-')[0],
          regionId: this.form.cityRegion.split('-')[0],
        };

        // if (nativeIds == null || nativeIds.length <= 0) {
        //   alert("请选择户口类型");
        //   return;
        // }
        if (valid) {
          if (this.form.id != undefined) {
            insurancefundInsuranceUpdate(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.$router.back();
                // this.open = false;
                // this.getList();
              });
          } else {
            insurancefundInsuranceSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.$router.back();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => insurancefundInsuranceDelete(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.online-info-page {
  table {
    td {
      padding: 10px 5px;
      //margin:0 5px;
    }
  }

  .dialog-footer {
    width: 200px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
